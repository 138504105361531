<template>
  <section>
    <b-row fluid>
      <b-col lg="8" cols="12" sm="12">
        <b-col cols="12">
          <b-card-actions
              class="cari-group-card"
              ref="cari-gruplar"
              title="Cari Gruplar"
              @refresh="refreshStop('cari-gruplar')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white">Cari Gruplar</h4>
                </b-col>
              </div>
            </b-row>
            <div class="d-flex justify-content-between flex-wrap mt-4">
              <b-form-group class="mr-1 mb-md-0 unPrint" size="sm">
                <b-input-group
                    size="sm"
                    prepend="Gösterilecek kayıt sayısı"
                    class="unPrint"
                >
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                  >
                  </b-form-select>
                </b-input-group>
              </b-form-group>
              <b-input-group size="sm" class="search-bar" :style="{width :width < 1400 ? '50%!important':''}">
                <b-input-group-prepend>
                  <b-button
                      :style="{height : width < 768 ? '30px':''}"
                      variant="outline-primary"
                      size="sm"
                      disabled
                      class="search-input mb-0"
                  >
                    Ara
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="filterInput" v-model="filter" type="search"/>
              </b-input-group>
            </div>
            <b-table
                striped
                hover
                responsive
                class="position-relative cari-group-table mt-1"
                :per-page="perPage"
                :current-page="currentPage"
                :items="groupGet"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-clicked="goCariListe"
            >
              <template #cell(grupAdi)="data">
                <b-badge variant="primary">
                  {{ data.item.grupAdi }}
                </b-badge>
              </template>
              <template #cell(aciklama)="data">
                <p>
                  {{ data.item.aciklama }}
                </p>
              </template>
              <template #cell(grupTuru)="data">
                <b-badge
                    variant="success"
                >
                  {{ grupTuruGet(data.item.grupTuru) }}
                </b-badge>
              </template>
              <template #cell(cariGrupID)="data">
                <b-button
                    @click="grupRemove(data.item.cariGrupID)"
                    class="btn-icon rounded-circle float-right trash-icon pull-up"
                    size="sm"
                    v-b-tooltip.hover.right="'Grubu Sil'"
                    v-if="$store.getters.getRole == 1"
                >
                  <feather-icon icon="TrashIcon"/>
                </b-button>
                <b-button
                    @click="editOn(data.item)"
                    variant="gradient-primary"
                    class="btn-icon rounded-circle float-right mr-50"
                    size="sm"
                    v-b-tooltip.hover.right="'Grubu Düzenle'"
                >
                  <feather-icon icon="EditIcon"/>
                </b-button>
                <b-button
                    v-b-modal.multi-select
                    @click="multiMethod(data.item.cariGrupID)"
                    class="btn-icon rounded-circle float-right mr-50 pull-up transfer-icon"
                    size="sm"
                    v-b-tooltip.hover.right="'Cari Kartları Aktar'"
                >
                  <font-awesome-icon icon="fa-solid fa-right-left"/>
                </b-button>
                <b-button
                    variant="gradient-success"
                    class="btn-icon rounded-circle float-right mr-50"
                    size="sm"
                    v-b-tooltip.hover.right="'Cari Kartlara Git'"
                    :to="'cariListe/' + data.item.cariGrupID"
                >
                  <feather-icon icon="UsersIcon"/>
                </b-button>
              </template>
            </b-table>
            <hr/>
            <b-row class="d-flex align-items-center mt-1">
              <b-col cols="6">
                <p class="text-muted">Toplam {{ totalRows }} Kayıt bulundu</p>
              </b-col>
              <b-col cols="6">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mb-0 float-right"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-col>
      <b-col v-if="!isEdit" lg="4" md="12" cols="12" sm="12">
        <b-col cols="12">
          <b-card-actions
              class="cari-group-add"
              ref="cari-grup-add"
              @refresh="refreshStop('cari-grup-add')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white">Grup Kaydı</h4>
                </b-col>
              </div>
            </b-row>
            <b-form @submit.prevent class="mt-4">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                      label="Grup Adı"
                      label-for="hi-first-name"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="hi-first-name"
                          placeholder="Grup Adı"
                          v-model="grupName"
                          autocomplete="off"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Açıklama"
                      label-for="hi-email"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="AlignCenterIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="hi-email"
                          placeholder="Açıklama"
                          v-model="grupContent"
                          autocomplete="off"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Grup Türü"
                      label-for="hi-email"
                      label-cols-md="4"
                  >
                    <model-list-select
                        class=""
                        :list="grupTuruOptions"
                        v-model="grupTuru"
                        option-value="value"
                        option-text="text"

                        name="unvan"
                        placeholder="Hızlı Seçim"
                    >
                    </model-list-select>
                  </b-form-group>
                </b-col>
                <b-col offset-md="4">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1 border-0"
                      @click="grupSave('kayit')"
                      :disabled="!grupName"
                      :style="{'backgroundColor': $store.getters.COLORS_GET.bgInfo +'!important'}"
                  >
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    Kaydet
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                      @click="grupTuruDefault"
                  >
                    <feather-icon
                        icon="ResetIcon"
                        class="mr-50"
                    />
                    Sıfırla
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-actions>
        </b-col>
      </b-col>
      <b-col v-if="isEdit" lg="4" md="12" cols="12" sm="12">
        <b-col cols="12">
          <b-card-actions
              class="cari-group-edit"
              ref="cari-group-edit"
              @refresh="refreshStop('cari-group-edit')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white">{{ editGroupName }} Grubu Düzenleniyor</h4>
                </b-col>
              </div>
            </b-row>
            <b-form @submit.prevent class="mt-4">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                      label="Grup Adı"
                      label-for="hi-first-name"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="hi-first-name"
                          placeholder="Grup Adı"
                          v-model="editData.grupAdi"
                          autocomplete="off"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Açıklama"
                      label-for="hi-email"
                      label-cols-md="4"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="AlignCenterIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="hi-email"
                          placeholder="Açıklama"
                          v-model="editData.aciklama"
                          autocomplete="off"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                      label="Grup Turu"
                      label-for="hi-email"
                      label-cols-md="4"
                  >
                    <model-list-select
                        class=""
                        :list="grupTuruOptions"
                        v-model="editData.grupTuru"
                        option-value="value"
                        option-text="text"

                        name="unvan"
                        placeholder="Hızlı Seçim"
                    >
                    </model-list-select>
                  </b-form-group>
                </b-col>
                <b-col offset-md="4">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1 border-0"
                      @click="grupSave('edit')"
                      :style="{'backgroundColor': $store.getters.COLORS_GET.bgInfo +'!important'}"
                  >
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    Güncelle
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                      @click="isEdit = false"
                  >
                    <feather-icon
                        icon="ResetIcon"
                        class="mr-50"
                    />
                    Vazgeç
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-actions>
        </b-col>
      </b-col>
    </b-row>
    <b-modal size="lg" hide-header id="multi-select" cancel-variant="success" ok-only hide-footer centered
             @hidden="transferHide"
    >
      <h3 class="pt-2 pl-2 pr-2 text-center">Grup Transferi</h3>
      <hr class="pl-2 pr-2">
      <b-row class="transfer-modal pl-3 pr-3">
        <b-col lg="8" cols="12" sm="12" class="mb-1">
          <v-multiselect-listbox v-model="transferList"
                                 :options="cariListGet"
                                 :reduce-display-property="(option) => option.firmaUnvan || option.adi + option.soyAdi"
                                 :reduce-value-property="(option) => option.cariKartID"
                                 no-options-text="Veri Yok"
                                 selected-no-options-text="Aktarılacak Cari Kartları Seçiniz"
                                 no-options-found-text="Bulunamadı"
                                 no-selected-options-found-text="Bulunamadı"
          />
        </b-col>
        <b-col lg="4" cols="12" sm="12">
          <b-form-group
              label="Aktarılacak Grup"
              label-for="hi-email"
          >
            <model-list-select
                class=""
                :list="grupTuruOptionsSwap"
                v-model="swapNewID"
                option-value="cariGrupID"
                option-text="grupAdi"

                name="unvan"
                placeholder="Hızlı Seçim"
            >
            </model-list-select>
          </b-form-group>
          <b-row>
            <b-col cols="12" class="text-center">
              <h4 class="font-weight-bold">
                {{ swapShow.old }}
              </h4>
              <font-awesome-icon v-if="swapShow.new" icon="fa-solid fa-chevron-down"/>
              <h4 class="font-weight-bold">
                {{ swapShow.new }}
              </h4>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr/>
      <div class="row justify-content-center p-2">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgPrimary"
                  class="myButton mr-1"
                  @click="grupAktar"
                  :disabled="!swapNewID"
        >Kaydet
        </b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import vMultiselectListbox from 'vue-multiselect-listbox-v2'
import 'vue-multiselect-listbox-v2/dist/vue-multi-select-listbox.css'
import 'vue-multiselect-listbox-v2/src/scss/vue-multi-select-listbox.scss'
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    vMultiselectListbox,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      editGroupName: '',
      oldTransferID: '',
      selectedList: [],
      transferList: [],
      editData: {
        cariGrupID: '',
        grupAdi: '',
        aciklama: '',
        grupTuru: [],
      },
      isEdit: false,
      swapNewID: '',
      swapOldID: '',
      grupTuruOptions: [
        {
          value: '1',
          text: 'Müşteriler',
        }, {
          value: '2',
          text: 'Tedarikçiler',
        }, {
          value: '3',
          text: 'Personeller',
        }, {
          value: '4',
          text: 'Diğer',
        },
      ],
      grupTuru: {
        value: '1',
        text: 'Müşteriler',
      },
      grupName: '',
      grupContent: '',
      fields: [
        {
          key: 'grupAdi',
          label: 'GRUP ADI',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'aciklama',
          label: 'GRUP AÇIKLAMASI',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'grupTuru',
          label: 'GRUP TÜRÜ',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'toplamCari',
          label: 'TOPLAM CARİ KART',
          sortable: true,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
        {
          key: 'cariGrupID',
          label: '',
          sortable: false,
          thStyle: {
            backgroundColor: store.getters.COLORS_GET.tableTitle || '#3c6e71',
            color: 'white',
            border: 'none',
          },
        },
      ],
      perPage: 10,
      pageOptions: [5, 10, 20, 50, 100, 200, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    swapShow() {
      if (this.swapNewID && this.oldTransferID) {
        let oldGroup = this.groupGet.find(x => x.cariGrupID === this.oldTransferID).grupAdi
        let newGroup = this.groupGet.find(x => x.cariGrupID === this.swapNewID).grupAdi
        return {
          old: oldGroup,
          new: newGroup,
        }
      }
      return ''
    },
    grupTuruOptionsSwap() {
      return this.groupGet.filter(object => object.cariGrupID !== this.oldTransferID)
    },
    groupGet() {
      return Object.values(store.getters.CARI_GROUP_GET)
    },
    cariListGet() {
      return Object.values(store.getters.CARI_LIST_GET)
    },
    roleControl() {
      return true
      // return store.getters.getRole === '1'
    },
  },
  methods: {
    goCariListe(item) {
      router.push({
        name: 'cariListe',
        params: { id: item.cariGrupID },
      })
    },
    grupAktar() {
      const formData = new FormData()
      formData.append('crm_token', store.state.userToken)
      formData.append('serviceName', 'cari')
      formData.append('methodName', 'cariGruplar')
      formData.append('islem', 'aktar')
      formData.append('eskiGrup', this.oldTransferID)
      formData.append('yeniGrup', this.swapNewID)
      this.transferList.forEach((item, i) => {
        formData.append([`updateList[${i}]`], item.cariKartID)
      })
      this.$store
          .dispatch('cariGroupSwap', formData)
          .then((res, position = 'bottom-right') => {
            this.swapNewID = ''
            this.oldTransferID = ''
            this.$bvModal.hide('multi-select')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: JSON.stringify(res.result.errMsg)
                    .slice(1, -1),
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
            this.$bvModal.hide('grup-swap')
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    multiMethod(cariGrupID) {
      this.oldTransferID = cariGrupID
      store.dispatch('cariList', cariGrupID)
    },
    editOn(item) {
      this.isEdit = true
      this.editGroupName = item.grupAdi
      this.editData.cariGrupID = item.cariGrupID
      this.editData.grupAdi = item.grupAdi
      this.editData.aciklama = item.aciklama
      this.editData.grupTuru = this.grupTuruOptions.find(x => x.value === item.grupTuru)
    },
    groupSwap() {
      let oldID = this.swapOldID
      let newID = this.swapNewID
      this.$store
          .dispatch('cariGroupSwap', {
            old: oldID,
            new: newID,
          })
          .then((res, position = 'bottom-right') => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: JSON.stringify(res.result.errMsg)
                    .slice(1, -1),
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
            this.swapNewID = ''
            this.swapOldID = ''
            this.$bvModal.hide('grup-swap')
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    grupTuruGet(id) {
      return id == 1 ? 'Müşteriler' : id == 2 ? 'Tedarikçiler' : id == 3 ? 'Personeller' : id == 4 ? 'Diğer' : false
    },
    grupRemove(id) {
      store.dispatch('cariGroupRemove', id)
          .then((res, position = 'bottom-right') => {
            this.totalRows = Object.values(res.cariGrupListesi).length
            if (res.result.status === 200) {
              if (res.result.errMsg) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: res.result.errMsg,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else if (res.result.status === 205) {
              this.$swal({
                title: res.result.errMsg,
                text: res.helpMsg,
                icon: 'error',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary mb-1',
                },
                buttonsStyling: false,
              })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    grupSave(islem) {
      store.dispatch('cariGroupSave', {
        tur: islem === 'kayit' ? this.grupTuru.value : this.editData.grupTuru.value,
        adi: islem === 'kayit' ? this.grupName : this.editData.grupAdi,
        aciklama: islem === 'kayit' ? this.grupContent : this.editData.aciklama,
        cariGrupID: islem === 'edit' ? this.editData.cariGrupID : '',
        islem,
      })
          .then((res, position = 'bottom-right') => {
            this.totalRows = Object.values(res.cariGrupListesi).length
            this.grupName = ''
            this.grupContent = ''
            this.grupTuruDefault()
            const mesaj = store.getters.notificationSettings(res)
            this.isEdit = false
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 1000)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    grupTuruDefault() {
      this.grupTuru = {
        value: '1',
        text: 'Müşteriler',
      }
    },
    transferHide() {
      this.swapNewID = ''
      this.transferList = []
    },
    swapHide() {
      this.swapNewID = ''
      this.swapOldID = ''
    },
  },
  created() {
    store.dispatch('cariGrup')
        .then(res => this.totalRows = Object.values(res.cariGrupListesi).length)
  },
  beforeDestroy() {
    store.commit('CARI_GROUP_ACTION', 'remove')
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup() {
    const {
      width,
    } = useWindowSize()
    return {
      width,
    }
  },
}
</script>
<style>
.fa-exchange-alt {
  display: none !important;
}

.msl-searchable-list__items {
  border-top: none !important;
}

.cari-group-table .table thead th, table tbody td {
  padding: 0.4rem !important;
}

.cari-group-card .card-header, .cari-group-add .card-header, .cari-group-edit .card-header {
  display: none !important;
}

.transfer-modal .msl-multi-select__list {
  width: 43% !important;
}
</style>
<style scoped>
.transfer-modal ::-webkit-scrollbar {
  width: 7px !important;
}

.transfer-modal ::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.transfer-modal ::-webkit-scrollbar-thumb {
  background: #888 !important;
}

.transfer-modal ::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
}

.cari-group-edit .card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
  background-color: #F48484 !important;
}

.col-8, .col-4 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.item-group-tur {
  position: absolute !important;
  margin-left: 3%;
}

.list-group-item {
  border: none !important;
  cursor: pointer !important;
  transition: 200ms;
}

.list-group-item:hover, span p {
  transition: 200ms;
  color: black !important;
}

.search-bar {
  width: 20% !important;
}

.search-input {
  border-color: #c3baba !important;
}

#filterInput:focus {
  border-color: #c3baba !important;
}

h4 {
  margin-bottom: 0 !important;
  padding: 6px;
}

.cari-group-table button {
  border: none !important;
}

.transfer-icon {
  background-color: #1F8A70 !important;
}

.trash-icon {
  background-color: #F55050 !important;
}

.transfer-modal .form-group {
  width: 100% !important;
  float: right !important;
}

.msl-multi-select {
  width: 108% !important;
}
</style>
